<template>
  <div>
    <div class="editor-parent" id="editor-parent"></div>
  </div>
</template>

<script>
import moduleLoader from "@/cc/ui-framework/4.23.15/moduleLoader.js";
const uiFrameworkBaseUrl = "../../cc/ui-framework/4.23.15";

export default {
  name: "MainCcEdit",
  props: {
    configName: {
      type: String,
      default: "config",
    },
  },
  computed: {
    TICKET() {
      return this.$store.getters["CC/Tickets"];
    },
    Hires() {
      return this.$store.getters["CC/Hires"];
    },
    Snapshot() {
      return this.$store.getters["CC/Snapshot"];
    },
    USER_ID() {
      return this.$store.getters["Main/contactID"];
    },
    bleed() {
      return this.TICKET[0].bleed / 0.705;
    },
    bleed2() {
      return this.TICKET[1].bleed / 0.705;
    },
    width() {
      return this.TICKET[0].width * 2.8346456693;
    },
    width2() {
      return this.TICKET[1].width * 2.8346456693;
    },
    height() {
      return this.TICKET[0].height * 2.8346456693;
    },
    height2() {
      return this.TICKET[1].height * 2.8346456693;
    },
    widthPreview() {
      return this.TICKET[0].width * 3.794;
    },
    heightPreview() {
      return this.TICKET[0].height * 3.794;
    },
  },
  methods: {
    async init() {
      let vm = this;
      const col =
        this.TICKET[0].doubleside !== undefined &&
        this.TICKET[0].doubleside !== null &&
        this.TICKET[0].doubleside === true
          ? 2
          : 1;
      const originals =
        this.TICKET[0].originals !== undefined &&
        this.TICKET[0].originals !== null
          ? this.TICKET[0].originals
          : 1;
      const template =
        this.TICKET[0].template !== undefined &&
        this.TICKET[0].template !== null &&
        this.TICKET[0].template.length > 0
          ? this.TICKET[0].template
          : "";
      const dae =
        this.TICKET[0]["3dmodel"] !== undefined &&
        this.TICKET[0]["3dmodel"] !== null &&
        this.TICKET[0]["3dmodel"].length > 0
          ? this.TICKET[0]["3dmodel"]
          : "";
      const page = col * originals;
      const sides1 =
        this.TICKET[0].sides !== undefined &&
        this.TICKET[0].sides !== null &&
        this.TICKET[0].sides !== ""
          ? parseInt(this.TICKET[0].sides)
          : 0;
      const sides2 =
        this.TICKET[1] !== undefined &&
        this.TICKET[1].sides !== undefined &&
        this.TICKET[1].sides !== null &&
        this.TICKET[1].sides !== ""
          ? parseInt(this.TICKET[1].sides)
          : 0;
      let configName;

      /*Рандомная строка для названия папки в которую будут складыватся загруженные изображения*/
      /*Используется, если загруженные изображения не привязываются к конкретному пользователю*/
      function randomString(i) {
        var rnd = "";
        while (rnd.length < i) rnd += Math.random().toString(36).substring(2);
        return rnd.substring(0, i);
      }

      const product = {
        id: 0,
        options: [],
        price: 1,
        attributes: [
          { id: 0, title: "width", value: this.width },
          { id: 1, title: "height", value: this.height },
          { id: 2, title: "page", value: page },
          { id: 3, title: "bleed", value: this.bleed },
          { id: 4, title: "template", value: template },
          { id: 5, title: "widthPreview", value: this.widthPreview },
          { id: 6, title: "heightPreview", value: this.heightPreview },
          { id: 7, title: "dae", value: dae },
          { id: 8, title: "path", value: randomString(36) },
        ],
      };
      console.log(product);
      let attributes2 = [];
      let desc1 = "";
      let desc2 = "";
      let ds = [];
      let sides = [];

      if (this.TICKET.length === 1) {
        if (template === "" && page === 1) configName = "edit-preflight-1";
        if (template === "" && page === 2) configName = "edit-preflight-2";
        if (template === "" && page > 2)
          configName = "edit-preflight-multypage";
        if (template !== "" && dae === "" && col === 1)
          configName = "front-maska";
        if (template !== "" && dae === "" && col === 2)
          configName = "front_back-maska";
        if (template !== "" && dae !== "" && col === 1)
          configName = "edit-maska-package";
      }
      if (this.TICKET.length === 2) {
        // console.log("----------------");
        // console.log(sides1);
        // console.log(sides2);
        if (sides1 > 0 && sides2 > 0) configName = "edit-preflight-multypage";
        if (sides1 === 0 && sides2 === 0) configName = "edit-preflight-double";
        // this.TICKET.sort((prev, next) => prev.order - next.order);

        desc1 =
          this.TICKET[0].desc !== null && this.TICKET[0].desc.length > 0
            ? this.TICKET[0].desc
            : "";
        desc2 =
          this.TICKET[1].desc !== null && this.TICKET[1].desc.length > 0
            ? this.TICKET[1].desc
            : "";
        if (sides1 === 0 && sides2 === 0) {
          this.TICKET.forEach((value, key) => {
            let doubleside = value.doubleside === true ? 2 : 1;
            ds[key] = doubleside;
            sides[key] = parseInt(value.originals);
            // console.log(doubleside);
          });
        }

        if (sides1 > 0 && sides2 > 0) {
          this.TICKET.forEach((value, key) => {
            sides[key] = parseInt(value.sides);
          });
        }

        // console.log(sides);
        // console.log(ds);
      }

      console.log(configName);
      const config = await moduleLoader.loadJson(
        "/config/" + configName + ".json"
      );
      // console.log(config);
      const driver = (
        await moduleLoader.dynamicImport(
          "ecommerceDriver",
          `${uiFrameworkBaseUrl}/drivers/default-driver.js`
        )
      ).ecommerceDriver;
      const editor = (
        await moduleLoader.dynamicImportDefault(
          "editor",
          `${uiFrameworkBaseUrl}/editor.js`
        )
      ).editor;
      const settings = {
        customersCanvasBaseUrl: "https://cc.gprint.ru/apps/design-editor/6.7.0",
      };

      config.vars.backendUrl = "https://cc.gprint.ru/apps/preflight/Preflight";
      if (this.TICKET.length === 1) {
        config.vars.pages = [];

        if (col === 1 && originals === 1)
          config.vars.pages.push({ title: "Лицевая сторона" });
        if (col === 2 && originals === 1) {
          config.vars.pages.push({ title: "Лицевая сторона" });
          config.vars.pages.push({ title: "Оборотная сторона" });
        }
        if (originals > 1) {
          if (col === 1) {
            for (let i = 1; i <= originals; i++) {
              config.vars.pages.push({ title: "Лицевая сторона " + i });
            }
          }
          if (col === 2) {
            for (let i = 1; i <= originals; i++) {
              config.vars.pages.push({ title: "Лицевая сторона " + i });
              config.vars.pages.push({ title: "Оборотная сторона " + i });
            }
          }
        }
      }

      if (this.TICKET.length === 2) {
        if (sides1 === 0 && sides2 === 0) {
          config.vars.pages1 = [];
          config.vars.pages2 = [];
          sides.forEach((s, index) => {
            if (index === 0) {
              if (ds[index] === 1 && s === 1)
                config.vars.pages1.push({ title: "Лицевая сторона" });
              if (ds[index] === 2 && s === 1) {
                config.vars.pages1.push({ title: "Лицевая сторона" });
                config.vars.pages1.push({ title: "Оборотная сторона" });
              }
              if (s > 1) {
                if (ds[index] === 1) {
                  for (let i = 1; i <= s; i++) {
                    config.vars.pages1.push({ title: "Лицевая сторона " + i });
                  }
                }
                if (ds[index] === 2) {
                  for (let i = 1; i <= s; i++) {
                    config.vars.pages1.push({ title: "Лицевая сторона " + i });
                    config.vars.pages1.push({
                      title: "Оборотная сторона " + i,
                    });
                  }
                }
              }
            } else {
              if (ds[index] === 1 && s === 1)
                config.vars.pages2.push({ title: "Лицевая сторона" });
              if (ds[index] === 2 && s === 1) {
                config.vars.pages2.push({ title: "Лицевая сторона" });
                config.vars.pages2.push({ title: "Оборотная сторона" });
              }
              if (s > 1) {
                if (ds[index] === 1) {
                  for (let i = 1; i <= s; i++) {
                    config.vars.pages2.push({ title: "Лицевая сторона " + i });
                  }
                }
                if (ds[index] === 2) {
                  for (let i = 1; i <= s; i++) {
                    config.vars.pages2.push({ title: "Лицевая сторона " + i });
                    config.vars.pages2.push({
                      title: "Оборотная сторона " + i,
                    });
                  }
                }
              }
            }

            attributes2 = [
              { id: 10, title: "width2", value: this.width2 },
              { id: 11, title: "height2", value: this.height2 },
              { id: 12, title: "page1", value: sides1 },
              { id: 13, title: "page2", value: sides2 },
              { id: 14, title: "desc1", value: desc1 },
              { id: 15, title: "desc2", value: desc2 },
            ];
          });
          attributes2.forEach((item) => {
            product.attributes.push(item);
          });
        }

        if (sides1 > 0 && sides2 > 0) {
          config.vars.pages = [];
          if (sides[0] === 2) {
            config.vars.pages.push({ title: "Обложка 1" });
            for (let s = 1; s <= sides[1]; s++) {
              config.vars.pages.push({ title: "Страница " + s });
            }
            config.vars.pages.push({ title: "Обложка 4" });
          }

          if (sides[0] === 4) {
            config.vars.pages.push({ title: "Обложка 1" });
            config.vars.pages.push({ title: "Обложка 2" });
            for (let s = 1; s <= sides[1]; s++) {
              config.vars.pages.push({ title: "Страница " + s });
            }
            config.vars.pages.push({ title: "Обложка 3" });
            config.vars.pages.push({ title: "Обложка 4" });
          }
        }
      }

      const quantity = 1;
      // const user_api = this.USER_ID;
      const user = { id: vm.USER_ID };

      config.vars.fileList = vm.Hires;
      if (sides1 === 0 && sides2 === 0) {
        config.vars.fileList1 = [vm.Hires[0]];
        config.vars.fileList2 = [vm.Hires[1]];
      }
      var snapshot = null;
      if (vm.Snapshot !== null) {
        snapshot = { snapshot: vm.Snapshot };
      }

      // Initialization - you should provide us with a product definition, editor instance,
      // config, data returned by the editor, items quantity (when it is necessary).
      const ecommerce = await driver.init(
        product,
        editor,
        config,
        settings,
        snapshot,
        quantity,
        user
      );

      // Display the editor in the specified div element
      ecommerce.products.current.renderEditor(
        document.getElementById("editor-parent")
      );

      if (originals > 1 && template !== "") {
        document
          .getElementById("editor-parent")
          .addEventListener("load", async () => {
            if (col === 1) {
              for (let i = 1; i < originals; i++) {
                if (
                  typeof document.querySelector("au-wizard").scope.$["cc"] !==
                  "undefined"
                ) {
                  await document
                    .querySelector("au-wizard")
                    .scope.$["cc"].addSurface({
                      printAreas: [{ designFile: "templates/" + template }],
                      name: "Лицевая сторона" + " " + (i + 1),
                    });
                }
              }
            }
            if (col === 2) {
              for (let i = 1; i < originals; i++) {
                if (
                  typeof document.querySelector("au-wizard").scope.$["cc"] !==
                  "undefined"
                ) {
                  await document
                    .querySelector("au-wizard")
                    .scope.$["cc"].addSurfaces({
                      file: "templates/" + template,
                      names: [
                        "Лицевая сторона" + " " + (i + 1),
                        "Оборотная сторона" + " " + (i + 1),
                      ],
                    });
                }
              }
            }
            if (
              typeof document.querySelector("au-wizard").scope.$["cc"] !==
              "undefined"
            ) {
              document
                .querySelector("au-wizard")
                .scope.$["cc"].switchSurfaceTo(0);
            }
          });
      }

      // Subscribe an event which will be called when the user finishes editing (you are supposed to put a code which
      // sends the product to a shopping cart).
      ecommerce.cart.onSubmitted.subscribe((data) => {
        data.lineItems.forEach(function (order) {
          let data = {
            oid: vm.$route.params.oId,
            hires: order.downloadUrls,
            images: order.images,
            snapshot: order.data.snapshot,
            stateId: order.data.stateId,
          };
          vm.$store.dispatch("CC/setLinks", data).then((response) => {
            if (response.data.status && response.data.status === 2000) {
              vm.$router.push({
                name: "order",
                params: { oId: vm.$route.params.oId },
              });
              vm.$notify({
                title: "Success",
                message: "Макет создан и успешно добавлен",
                type: "success",
                offset: 85,
                duration: 6000,
              });
            }
          });
          // vm.addToCart(data);
          // console.log("order: ", order);
          // console.log("Print_files: ", order.downloadUrls);
          // console.log("Previews: ", order.images);
          // console.log("snapshot: ", order.data.snapshot);
        });
      });
    },
  },
  mounted() {
    this.$store
      .dispatch("CC/getTicket", this.$route.params.oId)
      .then((response) => {
        if (response.data.status && response.data.status === 2000) {
          if (response.data.response) {
            this.init();
          }
        }
      });
  },
};
</script>

<style scoped>
.dropdown-container > label {
  margin-right: 8px;
  font-weight: bold;
  font-size: 12px;
}
.editor-parent {
  width: 100%;
  height: calc(100vh - 150px);
  min-height: 30rem;
}
.main__section-iframe {
}
.main__iframe {
  height: calc(100% - 8rem);
  width: 100%;
}
.main__iframe iframe {
  border: none;
  height: 100%;
  width: 100%;
}
.main__version {
  text-align: end;
  margin-top: 0.5rem;
  color: #2bafe6;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 10px;
}
.samples {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.samples__section {
  width: 24%;
}

@media (max-width: 450px) {
  .main {
    padding: 0;
  }
}
.main__section-iframe {
  width: 100%;
}
div.loader-wraper.icon-cc-logo > div.loader {
  position: relative !important;
}
.flex-container {
  display: flex;
}
.main__iframe__half {
  height: calc(100% - 8rem);
  width: 50%;
}
.main__iframe__half iframe {
  border: none;
  height: 100%;
  width: 100%;
}
</style>
